import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

const initialState = {
    token: "",
    exp: "",
}

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        isAuthUpdate: state => {
            state.token = localStorage.getItem("token")
            state.exp = localStorage.getItem("exp")
        },
        isAuthLogout: state => {
            localStorage.clear("token");
            localStorage.clear("exp");
            state.token = localStorage.getItem("token");
            state.exp = localStorage.getItem("exp");
            toast.success("Başarıyla çıkış yapıldı!");
        }
    }
})

export const { isAuthUpdate, isAuthLogout } = authSlice.actions;
export default authSlice.reducer;