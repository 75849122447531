import CurrencyTable from "./table/CurrencyTable";
import {motion} from "framer-motion";

export default function Currency() {

    const item = {
        hidden: {y: 20, opacity: 0},
        visible: {
            y: 0,
            opacity: 1
        }
    }

    return (
        <motion.section
            variants={item}
            id="currency"
            className="max-w-[80rem] mx-auto pb-4 px-4"
        >
            <CurrencyTable/>
        </motion.section>
    )
}