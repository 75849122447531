export default function ConfigParamUSD({ configData, priceData, setConfigData }) {
    return (
        <>
            <h1 className="font-semibold">USD</h1>
            <div className="flex flex-wrap gap-4">
                <div className="w-fit border border-black/40 p-2 rounded-md bg-white">
                    <h1>Alış fark</h1>
                    <input
                        className="bg-zinc-200 border border-black/40 rounded px-2 py-1"
                        type="number"
                        value={configData.USDT_BUY_DIFF}
                        onChange={(e) => setConfigData({
                            ...configData,
                            USDT_BUY_DIFF: e.target.value
                        })}
                    />
                    <div className="flex justify-between">
                        <span>Sonuç</span>
                        <span>{parseFloat(parseFloat(priceData[1].price[0]) + parseFloat(configData.USDT_BUY_DIFF)).toFixed(4)}</span>
                    </div>
                </div>
                <div className="w-fit border border-black/40 p-2 rounded-md bg-white">
                    <h1>Satış fark</h1>
                    <input
                        className="bg-zinc-200 border border-black/40 rounded px-2 py-1"
                        type="number"
                        value={configData.USDT_SELL_DIFF}
                        onChange={(e) => setConfigData({
                            ...configData,
                            USDT_SELL_DIFF: e.target.value
                        })}
                    />
                    <div className="flex justify-between">
                        <span>Sonuç</span>
                        <span>{parseFloat(parseFloat(priceData[1].price[1]) + parseFloat(configData.USDT_SELL_DIFF)).toFixed(4)}</span>
                    </div>
                </div>
            </div>
        </>
    )
}