export default function Middle() {
    return (
        <div className="flex justify-center ">
            <div className="max-w-[80rem] md:mb-8 p-4">
                {/* 1 Başlangıç */}
                <div className="grid grid-cols-1 grid-rows-1 lg:grid-cols-2 lg:grid-rows-1 gap-5 mb-8 ">
                    <div className=" flex justify-center">
                        <img src={"/safir1.jpg"}  className="object-cover rounded-3xl drop-shadow-xl transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-105  " alt="Safir Kuyumculuk Malatya, Altın kurları, Çeyrek altın fiyatları, Gram altın kurları, Safir Kuyumculuk altın fiyatları, Altın piyasa fiyatları, Güncel altın kurları, Kuyumcu altın kurları, Altın değerleri, Altın fiyatları güncel, Safir Kuyumculuk çeyrek altın, Altın alış-satış fiyatları, Safir Kuyumculuk gram altın, Altın değerleri güncel, Altın kuru bilgileri, Safir Kuyumculuk, Safir Kuyumculuk altın, malatya kuyumcu, güncel altın kurları" />
                    </div>
                    <div className=" color-[#2C2C2C] text-base flex flex-col justify-center text-justify rounded-3xl">
                        <div className="mb-5 leading-6">
                            Safir Kuyumculuk olarak misyonumuz, müşterilerimize en kaliteli ve özgün tasarımları sunarak onların özel anlarını daha da değerli kılmaktır. Her bir mücevher parçamız, ince işçilik ve titiz bir tasarım süreci sonucunda ortaya çıkmaktadır. Müşterilerimizin hayallerini gerçeğe dönüştürmek ve onlara benzersiz bir deneyim yaşatmak için çalışıyoruz.
                        </div>
                        <div className="mb-5 leading-6">
                            Müşteri memnuniyetini her zaman en üst seviyede tutmayı hedefliyoruz. Satış öncesi ve sonrası hizmetlerimizle, müşterilerimizin her türlü ihtiyacını karşılamayı ve onlara güvenilir bir alışveriş deneyimi sunmayı amaçlıyoruz. Dürüstlük ve şeffaflık ilkelerine sadık kalarak, müşterilerimizle uzun soluklu ilişkiler kuruyoruz.
                        </div>
                        <div className="mb-5 leading-6">
                            Kalite, güven ve müşteri memnuniyeti odaklı çalışma anlayışımızla, sektördeki yenilikleri takip ediyor ve en yeni trendleri koleksiyonlarımıza yansıtıyoruz. Safir Kuyumculuk olarak, her müşterimizin kendini özel hissetmesini sağlamak için var gücümüzle çalışıyor ve mücevherat dünyasında iz bırakmayı hedefliyoruz.
                        </div>
                    </div>
                </div>

                {/* 1 Bitiş */}

                {/* 2 Başlangıç */}
                <div className="grid grid-cols-1 grid-rows-1 lg:grid-cols-2 lg:grid-rows-1  gap-5 ">
                    <div className=" color-[#2C2C2C] text-base flex flex-col justify-center  text-justify order-last sm:order-last md:order-first lg:order-first xl:order-first rounded-3xl">
                        <div className="mb-5 leading-6">
                        Safir Kuyumculuk olarak vizyonumuz, mücevherat sektöründe yenilikçi tasarımlarımız ve üstün kalite anlayışımız ile tanınan ve tercih edilen lider bir marka olmaktır. Müşterilerimize sunduğumuz her parça ile onların hayatındaki özel anları daha anlamlı ve unutulmaz kılmak için sürekli olarak kendimizi yeniliyor ve geliştiriyoruz. Bu doğrultuda, dünya çapında tanınan ve saygı duyulan bir marka olmayı hedefliyoruz.
                        </div>
                        <div className="mb-5 leading-6">
                        Sürdürülebilirlik ve etik değerler, iş yapış şeklimizin temel taşlarını oluşturur. Çevreye duyarlı üretim süreçleri ve adil ticaret prensipleri ile çalışarak, hem doğaya hem de topluma katkıda bulunmayı amaçlıyoruz. Bu yaklaşımımızla, geleceğe daha yaşanabilir bir dünya bırakmak için üzerimize düşen sorumluluğun bilincindeyiz ve bu doğrultuda çalışmalarımızı sürdürüyoruz.
                        </div>
                        <div className="mb-5 leading-6">
                        Teknolojiyi ve sektördeki yenilikleri yakından takip ederek, müşterilerimize en yeni ve en iyi hizmetleri sunmayı amaçlıyoruz. Safir Kuyumculuk olarak, sürekli gelişim ve mükemmeliyet arayışımızla, mücevherat dünyasında fark yaratan bir marka olmayı sürdüreceğiz. Müşterilerimizin bize olan güvenini her zaman koruyarak, onlara en iyisini sunmak için var gücümüzle çalışmaya devam edeceğiz.
                        </div>

                    </div>
                    <div className=" flex justify-center">
                        <img src={"/safir2.jpg"} className="object-cover rounded-3xl drop-shadow-xl transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-105 " alt="Safir Kuyumculuk Malatya, Altın kurları, Çeyrek altın fiyatları, Gram altın kurları, Safir Kuyumculuk altın fiyatları, Altın piyasa fiyatları, Güncel altın kurları, Kuyumcu altın kurları, Altın değerleri, Altın fiyatları güncel, Safir Kuyumculuk çeyrek altın, Altın alış-satış fiyatları, Safir Kuyumculuk gram altın, Altın değerleri güncel, Altın kuru bilgileri, Safir Kuyumculuk, Safir Kuyumculuk altın, malatya kuyumcu, güncel altın kurları" />
                    </div>
                </div>
                {/* 2 Bitiş */}

                {/* 3 Başlangıç */}
                <div className="grid grid-cols-1 grid-rows-1 lg:grid-cols-2 lg:grid-rows-1 gap-5 mt-8 ">
                    <div className=" flex justify-center">
                        <img src={"/black-background.jpg"}  className="  object-cover rounded-3xl drop-shadow-xl transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-105 " alt="Safir Kuyumculuk Malatya, Altın kurları, Çeyrek altın fiyatları, Gram altın kurları, Safir Kuyumculuk altın fiyatları, Altın piyasa fiyatları, Güncel altın kurları, Kuyumcu altın kurları, Altın değerleri, Altın fiyatları güncel, Safir Kuyumculuk çeyrek altın, Altın alış-satış fiyatları, Safir Kuyumculuk gram altın, Altın değerleri güncel, Altın kuru bilgileri, Safir Kuyumculuk, Safir Kuyumculuk altın, malatya kuyumcu, güncel altın kurları" />
                    </div>
                    <div className=" color-[#2C2C2C] text-base flex flex-col justify-center text-justify text-black pt-4 rounded-3xl">
                        <div className="mb-5 leading-6">
                        Safir Kuyumculuk, yılların tecrübesi ve ustalığı ile mücevherat sektöründe hizmet veren köklü bir markadır. Kurulduğumuz günden bu yana, müşteri memnuniyetini en ön planda tutarak, zarif ve özgün tasarımlarımızla müşterilerimizin özel anlarını daha da unutulmaz kılmayı amaçladık. Her bir mücevher parçamız, titiz bir işçilikle ve özenle hazırlanmakta olup, kalite standartlarımızdan asla ödün vermemekteyiz.
                        </div>
                        <div className="mb-5 leading-6">
                        Mücevherat alanında yenilikçi ve modern tasarımlar sunarak, sektörde fark yaratmayı hedefliyoruz. Deneyimli ve uzman kadromuzla, her müşterimizin zevkine ve beklentisine hitap eden özel koleksiyonlar hazırlıyoruz. Safir Kuyumculuk olarak, müşteri odaklı hizmet anlayışımızla, satış öncesi ve sonrası desteğimizle müşterilerimize en iyi alışveriş deneyimini sunmaktayız. Dürüstlük ve güvenilirlik ilkelerimiz doğrultusunda, her zaman şeffaf ve adil bir hizmet sunmayı taahhüt ediyoruz.
                        </div>
                        <div className="mb-5 leading-6">
                        Safir Kuyumculuk olarak, sürdürülebilirlik ve etik değerler çerçevesinde faaliyetlerimizi sürdürmekteyiz. Çevreye duyarlı üretim süreçlerimiz ve sosyal sorumluluk projelerimiz ile topluma katkıda bulunmayı görev edindik. Geleceğe daha yaşanabilir bir dünya bırakma misyonumuzla, yenilikçi ve sorumlu iş anlayışımızı her geçen gün daha da ileriye taşıyoruz. Müşterilerimizin güveni ve memnuniyeti ile büyümeye devam eden markamız, mücevherat dünyasında kalıcı izler bırakmayı hedeflemektedir.
                        </div>

                    </div>
                </div>

                {/* 3 Bitiş */}




            </div>
        </div>
    )
}