import { motion } from "framer-motion";
import { useSelector } from "react-redux";

export default function CurrencyTable() {

    const item = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
    }

    const currencyData = useSelector(state => state.currencyData.value)

    return (
        <motion.div
            className={"w-full bg-[#505050] text-white py-4 md:py-8 rounded-lg uppercase px-2 md:px-8"}
            variants={item}
        >
            <div className="flex justify-between border-b pb-4 border-black/20 text-base sm:text-lg md:text-2xl font-extrabold text-white">
                <div className="w-full">Kod</div>
                <div className={"flex w-full"}>
                    <div className="w-full text-center">Alış</div>
                    <div className="w-full text-center">Satış</div>
                </div>
            </div>

            {currencyData.map((item, index) => {

                return (
                    <div key={index} className="flex gap-4 md:gap-0 justify-between border-b py-4 border-black/20 text-base sm:text-lg md:text-2xl font-semibold overflow-hidden">
                        <div className="text-wrap w-full">{item.title == "XAU" ? "XAU (24 AYAR)" : item.title}</div>
                        <div className={"flex items-center w-full md:gap-0 gap-4"}>
                            {
                                item.price.length < 2 ?
                                    <div className="w-full text-center">{item.price[0]}</div>
                                    :
                                    <>
                                        <div className="w-full text-center">{item.price[0]}</div>
                                        <div className="w-full text-center">{item.price[1]}</div>
                                    </>
                            }
                        </div>
                    </div>
                )
            })}

        </motion.div>
    )
}