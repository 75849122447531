import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isLoading: true,
    value: [],
    createdAt: new Date().toUTCString(),
}

export const dataSlice = createSlice({
    name: "currencyData",
    initialState,
    reducers: {
        updateData: (state, action) => {
            state.value = action.payload.prices
            state.isLoading = false
            state.createdAt = action.payload.createdAt
        },
    }
})

export const {updateData} = dataSlice.actions;
export default dataSlice.reducer;