import Currency from "../components/currency/Currency";
import CurrencyHeader from "../components/currency/header/CurrencyHeader";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { updateData } from "../store/features/dataSlice";
import Loading from "../components/loading/Loading";
import { motion } from "framer-motion";
import Title from "../components/title";
import Middle from "../components/middle";
import { Helmet } from "react-helmet"

export default function Home() {

    const data = useSelector((state) => state.currencyData)
    const dispatch = useDispatch();

    useEffect(() => {
        // Fetch data immediately when the component mounts
        const fetchData = () => {
            fetch(`${process.env.REACT_APP_API_URL}/api/prices`)
                .then(res => res.json())
                .then(data => {
                    dispatch(updateData(data))
                })
        };

        fetchData();


        const timer = setInterval(fetchData, 10000); // Fetch every 10 seconds

        return () => clearInterval(timer);
    }, [dispatch]);

    const container = {
        visible: {
            transition: {
                delayChildren: 0.3,
                staggerChildren: 0.2
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Güncel Altın ve Döviz Kurları | Safir Kuyumculuk</title>
                <meta name="description" content="En güncel altın ve döviz kurları bilgilerini burada bulabilirsiniz. Safir Kuyumculuk olarak sürekli güncellenen verilerle sizlere en iyi hizmeti sunuyoruz." />
                <meta name="robots" content="index,follow" />
                <meta property="og:title" content="Güncel Altın ve Döviz Kurları | Safir Kuyumculuk" />
                <meta property="og:description" content="En güncel altın ve döviz kurları bilgilerini burada bulabilirsiniz. Safir Kuyumculuk olarak sürekli güncellenen verilerle sizlere en iyi hizmeti sunuyoruz." />
                <meta name="keywords" content="Malatya safir kuyumculuk, safir kuyumculuk malatya, safir kuyumculuk, altın kurları, döviz kurları, Güncel altın fiyatları, güncel döviz kurları, Malatya kuyumcu fiyatları, Altın kurları,Güncel döviz ve altın" />

            </Helmet>
            <div className="wrapper bg-white text-black">
                <div className="flex justify-center flex-col items-center py-10">

                    <div className="">
                        <h1 className="w-full text-lg md:text-xl lg:text-2xl xl:text-3xl font-bold color-[#2C2C2C] my-4 tracking-wider">ALTIN VE DÖVİZ KURLARI</h1>
                    </div>
                </div>
                <section
                    id="home"
                >

                    {
                        data.isLoading
                            ?
                            <Loading />
                            :
                            <motion.div
                                initial="hidden"
                                animate="visible"
                                variants={container}
                            >
                                <CurrencyHeader />
                                <Currency />
                            </motion.div>}
                </section>
                <div>
                    <div>
                        <Title />
                    </div>
                    <div>
                        <Middle />
                    </div>

                </div>
            </div>
        </>
    )
}