import PanelBody from "./body/PanelBody";
import PanelHeader from "./header/PanelHeader";

export default function Panel(){
    return(
        <div>
            <PanelHeader/>
            <PanelBody/>
        </div>
    )
}