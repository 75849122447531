export default function NotFound() {
    return (
        <section
            id="NotFound"
            className="flex justify-center items-center h-screen"
        >
            <div className="flex flex-col items-center gap-4">
                <span className="text-3xl">Sayfa bulunamadı!</span>
                <a href="/">
                    <span className="bg-[#505050] px-2 py-1 text-white rounded">Geri dön</span>
                </a>
            </div>
        </section>
    )
}