import { useDispatch, useSelector } from "react-redux";
import Login from "../components/admin/login/Login";
import Panel from "../components/admin/panel/Panel";
import { useEffect, useState } from "react";
import { isAuthUpdate } from "../store/features/auth";
import momentTz from "moment-timezone";
import toast from "react-hot-toast";

export default function Admin() {

    const { token, exp } = useSelector(state => state.auth);
    const [loginStatus, setLoginStatus] = useState(false);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(isAuthUpdate())
        if(token && exp){
            const now = momentTz().tz("Europe/Istanbul");
            if(now.isAfter(exp)){
                toast.error("Oturum zaman aşımına uğradı, lütfen tekrar giriş yapınız!");
            }else{
                setLoginStatus(true)
            }
        }else{
            setLoginStatus(false)
        }
    }, [token,exp])

    return (
        <div>
            {
                loginStatus ?
                    <Panel />
                    :
                    <Login />
            }
        </div>
    )
}