import { FaInstagram} from "react-icons/fa";
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <footer
            id="footer"
            className="bg-[#3b3b3b] border-t text-white border-white pb-10 px-4"
        >
            <div className="max-w-[90rem] mx-auto py-10 flex flex-col md:flex-row justify-between gap-10 xl:gap-0">
                <div className="flex flex-col items-center w-full text-center">
                    <div className="text-4xl poppins-bold">
                        Safir Kuyumculuk
                    </div>
                    <p className="poppins-extralight-italic pt-4">
                        Zarafetin Safirle Buluştuğu Yer
                    </p>
                    <div className="flex gap-4 pt-4">
                        <Link target="_blank" to="https://www.instagram.com/safirgold_malatya/">   <FaInstagram /> </Link>

                    </div>
                </div>
                <div className="flex flex-col md:flex-row w-full gap-10 lg:gap-0">
                    <div className="flex flex-col mb-4 w-full items-center">
                        <h1 className="poppins-bold text-xl">Menü</h1>
                        <ul className=" text-white">
                            <li className="w-fit">
                                <a href="/#" target="_self" className="flex items-center gap-1"><span>-</span><span>Anasayfa</span></a>
                            </li>
                        </ul>
                    </div>

                    <div className="w-full flex flex-col items-center">
                        <div>
                            <h1 className="poppins-bold text-xl">Şubelerimiz</h1>
                        </div>
                        <div className="mt-4">
                            <div className="text-sm flex flex-col gap-2">

                                <div className="mb-2 flex flex-row gap-4 ">
                                    <span className="text-base font-bold flex flex-row text-nowrap">Şube 1:</span>
                                    <span className="pt-0.5">
                                        MERKEZ ŞUBE Atatürk Cad. Hamidiye Mah. 17/C Battalgazi/MALATYA
                                    </span>
                                </div>

                                <div className="mb-2 flex flex-row gap-4 ">
                                    <span className="text-base font-bold flex flex-row text-nowrap">Şube 2:</span>
                                    <span className="pt-0.5">
                                        Kapalı Çarşı No:1 Kapı No:Z4 Battalgazi/MALATYA
                                    </span>
                                </div>

                                <div className="mb-2 flex flex-row gap-4 ">
                                    <span className="text-base font-bold flex flex-row text-nowrap">Şube 3:</span>
                                    <span className="pt-0.5">
                                    Sivas Cad. Kuyumcular Çarşısı No:1 Battalgazi/MALATYA

                                    </span>
                                </div>

                                <div className="mb-2 flex flex-row gap-4 ">
                                    <span className="text-base font-bold flex flex-row text-nowrap">Şube 4:</span>
                                    <span className="pt-0.5">
                                        Ceviz Mah. Tugay Yolu Piazza AVM Kat:1 Blue Diamond Maltepe/İSTANBUL
                                    </span>
                                </div>



                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full text-sm text-white/50 text-center pb-2">
                Copyright © 2024 <Link target="_blank" to="https://onsinovasyon.com.tr/"   >ONS İnovasyon </Link>Tüm Hakları Saklıdır.
            </div>
        </footer>
    )
}