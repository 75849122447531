import { useEffect, useState } from "react"
import toast from 'react-hot-toast';
import PricesTable from "./PricesTable";
import SaveButton from "./SaveButton";
import ConfigParamXAU from "./ConfigParamXAU";
import ConfigParamUSD from "./ConfigParamUSD";
import ConfigParamEUR from "./ConfigParamEUR";

export default function PanelBody() {

    const [priceData, setPriceData] = useState([]);
    const [configData, setConfigData] = useState({});
    const [loading, setLoading] = useState(true);

    const notify = (e) => {
        if (e === "success") {
            toast.success(e);
        } else if (e === "error") {
            toast.error(e);
        } else {
            toast(e);
        }
    };

    useEffect(() => {
        const url = `${process.env.REACT_APP_API_URL}/admin/panel`;
        const token = localStorage.getItem("token");

        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(res => res.json())
            .then(data => {
                setPriceData(data.aData);
                setConfigData(data.configData);
                setLoading(false);
            })
    }, []);

    const saveHandle = async () => {
        const url = `${process.env.REACT_APP_API_URL}/admin/save`;
        const token = localStorage.getItem("token");

        let responseMessega = await fetch(url, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                payload: {
                    buyProfit: parseFloat(configData.buyProfit).toFixed(4),
                    sellProfit: parseFloat(configData.sellProfit).toFixed(4),
                    USDT_BUY_DIFF: parseFloat(configData.USDT_BUY_DIFF).toFixed(4),
                    USDT_SELL_DIFF: parseFloat(configData.USDT_SELL_DIFF).toFixed(4),
                    EUR_BUY_DIFF: parseFloat(configData.EUR_BUY_DIFF).toFixed(4),
                    EUR_SELL_DIFF: parseFloat(configData.EUR_SELL_DIFF).toFixed(4),
                }
            })
        })

        responseMessega = await responseMessega.json();
        notify(responseMessega.status);

    }

    return (
        <div>
            {
                !loading &&
                <>
                    <PricesTable priceData={priceData} />
                    <div
                        className="bg-zinc-200 border-y border-black/30 p-8 md:text-base text-sm"
                    >
                        <h1 className="font-semibold text-center">
                            Ayarlar
                        </h1>
                        <div>
                            <div className="py-2 flex flex-col gap-6 items-center">
                                <ConfigParamXAU configData={configData} priceData={priceData} setConfigData={setConfigData} />
                                <ConfigParamUSD configData={configData} priceData={priceData} setConfigData={setConfigData} />
                                <ConfigParamEUR configData={configData} priceData={priceData} setConfigData={setConfigData} />
                            </div>
                        </div>
                    </div>
                    <SaveButton saveHandle={saveHandle} />
                </>
            }
        </div>
    );
}
