export default function Header() {

    return (
        <header className="border-b border-solid border-white bg-[#484848] py-10">
            <div className="max-w-[80rem] mx-auto flex flex-col items-center px-5 ">
                <div className="max-w-[36rem] flex flex-col items-center my-5 text-center">
                    <div className="flex flex-col justify-center">
                        <h1 className="text-3xl lg:text-4xl xl:text-5xl text-center leading-tight font-bold mb-4 text-white">
                            SAFİR KUYUMCULUK
                            </h1>
                            
                            {/* <img src="/safirLogo.png" alt=""  className="" style={{ filter: 'drop-shadow(2px 10px 4px rgba(59, 34, 6, 0.8))' }} /> */}
                        <span className="font-bold text-4xl text-[#fff] tracking-wider">Malatya</span>
                    </div>
                </div>
            </div>
        </header>
    )
}
