import { useState } from "react";
import { useDispatch } from "react-redux";
import { isAuthUpdate } from "../../../store/features/auth";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export default function LoginForm() {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [hidden, setHidden] = useState(true);
    const dispatch = useDispatch();

    const submitHandle = (e) => {
        e.preventDefault();
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        fetch(`${process.env.REACT_APP_API_URL}/admin/login?username=${username}&password=${password}`, {
            method: "POST",
        })
            .then(res => res.json())
            .then(data => {
                if (data.status === 200 && data.messageType === "success" && data.token) {
                    localStorage.setItem("token", data.token);
                    localStorage.setItem("exp", data.expiresDate);
                    dispatch(isAuthUpdate())
                } else {
                    toast.error("Oturum açma başarısız! Lütfen bilgileri kontrol ettikten sonra tekrar deneyiniz.")
                    setUsername("");
                    setPassword("");
                }
            })
    }

    return (
        <div className="bg-black/10 min-w-[20rem] p-4 rounded border border-blue-400">
            <form
                onSubmit={submitHandle}
            >
                <h1 className="text-center text-4xl italic text-blue-800 pb-4">Admin Panel</h1>
                <div className="flex flex-col gap-4">
                    <input
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                        placeholder="Kullancı adı"
                        className="bg-transparent bg-white p-2"
                    />
                    <div className="flex relative">
                        <input
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            type={hidden ? "password" : "text"}
                            placeholder="Şifre"
                            className="bg-transparent bg-white p-2 w-full"
                        /> 
                        <button
                            className="w-fit absolute right-2 top-3"
                            type="button"
                            onClick={() => setHidden(!hidden)}>
                            {hidden ? <FaEye /> : <FaEyeSlash />}
                        </button>
                    </div>
                </div>
                <div className="w-full flex flex-col gap-2">

                    <button
                        type="submit"
                        className="bg-blue-900 text-white w-full p-2 mt-4"
                    >
                        Giriş yap
                    </button>
                    <Link to={"/"}>
                        <div className="px-4 py-2 bg-black text-white text-center">
                            Anasayfa
                        </div>
                    </Link>
                </div>

            </form>
        </div>
    )
}