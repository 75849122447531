export default function PricesTable({priceData}){
    return(
        <div
                className="bg-zinc-200 md:text-base text-sm"
            >
                <div className="p-8">
                    <h1 className="font-semibold pb-4 text-center">Fiyatlar</h1>
                    <div className="flex flex-wrap justify-center gap-4">
                        {priceData.map((pd, index) => {
                            return (
                                <div
                                    key={index}
                                    className="bg-white border rounded-md px-4 py-2 shadow-sm min-w-full md:min-w-[15rem]">
                                    <h1 className="font-semibold">{pd.title}</h1>
                                    <div className="flex border-b text-center w-full justify-between">
                                        <span>Alış</span>
                                        <span>Satış</span>
                                    </div>
                                    <div className="flex justify-between">
                                        <div>
                                            <span className="font-bold">
                                                {pd.price[0]}
                                            </span>
                                        </div>
                                        <div>
                                            <span className="font-bold">
                                                {pd.price[1]}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
    )
}