import {motion} from "framer-motion";
import {useSelector} from "react-redux";

export default function CurrencyHeader() {

    const createdAt = useSelector(state => state.currencyData.createdAt)

    const item = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1
        }
    }

    return (
        <motion.section
            variants={item}
            id="currencyHeader"
            className="max-w-[80rem] mx-auto pb-8 text-center flex flex-col gap-2"
        >
            <h1 className="uppercase text-center lg:text-base md:text-sm text-xs">Son Güncellenme Zamanı</h1>
            <span className="text-sm font-thin text-black/80">{createdAt}</span>
        </motion.section>
    )
}