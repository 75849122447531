export default function Title() {
    return (
        <div className="text-center my-2">
            <div className="mb-5">
                <p className="mb-5 text-[#505050] font-medium text-sm md:text-base">Fiyatlarımız bilgi amaçlıdır, değişkenlik gösterebilir.</p>
                <h1 className="text-xl font-medium leading-6 tracking-[10px] uppercase text-[#FFBF00] drop-shadow-md pt-4">HAKKINDA</h1>
            </div>
            <div className="mb-5">
                <h1 className="text-center uppercase text-4xl font-bold color-[#2C2C2C] my-10 px-4 tracking-wider">SAFİR KUYUMCULUK MALATYA</h1>
            </div>
        </div>
    )
}