import { useDispatch } from "react-redux"
import { isAuthLogout } from "../../../../store/features/auth";
import { Link } from "react-router-dom";

export default function PanelHeader() {

    const dispatch = useDispatch();

    return (
        <header
            className="w-full bg-blue-900 text-white px-4"
        >
            <div
                className="max-w-[80rem] py-8 mx-auto flex items-center justify-between"
            >
                <h1 className="text-base md:text-2xl font-semibold">Admin Panel</h1>
                <div className="flex items-center gap-4">
                    <Link to={"/"}>
                        <button className="px-2 py-1 md:px-4 md:py-2 font-semibold rounded-md bg-white text-blue-900">
                            Anasayfa
                        </button>
                    </Link>
                    <button
                        onClick={() => dispatch(isAuthLogout())}
                        className="bg-white text-blue-900 px-2 py-1 md:px-4 md:py-2 font-semibold rounded-md">
                        Çıkış yap
                    </button>
                </div>
            </div>
        </header>
    )
}