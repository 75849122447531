export default function ConfigParamXAU({ configData, priceData, setConfigData }) {
    return (
        <>
            <h1 className="font-semibold">XAU</h1>
            <div className="flex flex-wrap gap-4">
                <div className="w-fit border border-black/40 p-2 rounded-md bg-white">
                    <h1>Alış çarpan katsayı</h1>
                    <input
                        className="bg-zinc-200 border border-black/40 rounded px-2 py-1"
                        type="number"
                        value={configData.buyProfit}
                        onChange={(e) => setConfigData({
                            ...configData,
                            buyProfit: e.target.value
                        })}
                    />
                    <div className="flex justify-between">
                        <span>Sonuç</span>
                        <span>{parseFloat(priceData[0].price[0] * parseFloat(configData.buyProfit)).toFixed(4)}</span>
                    </div>
                </div>
                <div className="w-fit border border-black/40 p-2 rounded-md bg-white">
                    <h1>Satış çarpan katsayı</h1>
                    <input
                        className="bg-zinc-200 border border-black/40 rounded px-2 py-1"
                        type="number"
                        value={configData.sellProfit}
                        onChange={(e) => setConfigData({
                            ...configData,
                            sellProfit: e.target.value
                        })}
                    />
                    <div className="flex justify-between">
                        <span>Sonuç</span>
                        <span>{parseFloat(priceData[0].price[1] * parseFloat(configData.sellProfit)).toFixed(4)}</span>
                    </div>
                </div>
            </div>
        </>
    )
}